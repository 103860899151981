import { PATH_URL } from "@/constants";
import ApiInstance from "./axiosBase";
import { removeNull, toParams } from "@/utils/ObjectUtils";
import {
  DATA_STORE_SALE_ANALYTICS,
  DATA_STORE_SALE_ANALYTICS_BY_PRODUCT,
  DATA_STORE_SALE_ANALYTICS_BY_STORE,
  DATA_STORE_TRANSACTION_HISTORY,
  DATA_STORE_TRANSACTION_HISTORY_DETAIL,
} from "@/store/sampleData";

export const AdminGetListStore = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/list` + params);
};

export const GetDetailStore = (id: any) => {
  return ApiInstance.get(`${PATH_URL.STORE}/${id}`);
};

export const UpdateDisplayStore = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/update/display`, body);
};

export const GetListDrawer = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/drawer/list`);
};

export const GetListPrint = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/print/list`);
};

export const GetListInventory = (params: any) => {
  const rest = removeNull(params);
  return ApiInstance.get(`${PATH_URL.STORE}/inventory/list`, { params: rest });
};

export const GetListCashbackMachine = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/cashback-machine/list`);
};

export const DeleteStore = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/delete/${id}`);
};

export const DeleteDrawer = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/drawer/delete/${id}`);
};

export const DeletePrint = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/print/delete/${id}`);
};

export const DeleteCashbackMachine = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/cashback-machine/delete/${id}`);
};

export const DeletePosMachine = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/pos-machine/delete/${id}`);
};

export const DetailPosMachine = (id: number) => {
  return ApiInstance.get(`${PATH_URL.STORE}/pos-machine/detail/${id}`);
};

export const UpdateDisplay = (body: { id: number; isDisplay: number }) => {
  return ApiInstance.put(
    `${PATH_URL.STORE}/pos-machine/update/display/${body.id}`,
    { isDisplay: body.isDisplay }
  );
};

export const AdminGetListPosMachine = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.STORE}/pos-machine/list` + params);
};

export const CreateStore = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/create`, body);
};

export const UpdateStore = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/update/${id}`, rest);
};

export const UpdatePosMachine = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/pos-machine/update/${id}`, rest);
};

export const UpdatePosMachines = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/pos-machine/bulk-update`, body);
};

export const UpdatePrint = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/print/update/${id}`, rest);
};

export const UpdateCashbackMachine = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(
    `${PATH_URL.STORE}/cashback-machine/update/${id}`,
    rest
  );
};

export const CreatePrint = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/print/create`, body);
};

export const CreateCashbackMachine = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/cashback-machine/create`, body);
};

export const CreatePosMachine = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/pos-machine/create`, body);
};

export const CreateDrawer = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/drawer/create`, body);
};

export const GetListCategory = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/category/list` + params);
};

export const DeleteCategory = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/category/${id}`);
};

export const DetailCategory = (id: number) => {
  return ApiInstance.get(`${PATH_URL.STORE}/category/${id}`);
};

export const UpdateDisplayCategory = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/category/update/display`, body);
};
export const UpdateChildDisplayCategory = (body: any) => {
  return ApiInstance.put(
    `${PATH_URL.STORE}/category/update/child/display`,
    body
  );
};

export const CreateCategory = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/category/create`, body);
};

export const UpdateCategory = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(
    `${PATH_URL.STORE}/category/update/${id}`,
    rest.categoryLanguages
  );
};

export const DownloadCSVCategory = () => {
  ApiInstance.get(`${PATH_URL.STORE}/category/export-csv`, {
    responseType: "blob",
  }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "カテゴリ登録.csv");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const UpdateCategories = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/categories/update`, body);
}

export const UpdateInventories = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/inventory/bulk-update`, body);
}

export const GetListPaymentType = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/payment-type/list`);
};

export const CreatePaymentType = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/payment-type/create`, body);
};

export const DeletePaymentType = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/payment-type/delete/${id}`);
};

export const GetListRfid = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/rfid/list` + params);
};

export const DeleteRfid = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/rfid/delete/${id}`);
};

export const CreateRfid = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/rfid/create`, body);
};

export const GetListPaymentMethod = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/payment-method/list`);
};

export const CreatePaymentMethod = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/payment-method/create`, body);
};

export const DeletePaymentMethod = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/payment-method/delete/${id}`);
};

export const GetStoreSetting = () => {
  return ApiInstance.get(`setting/detail`);
};

export const UpdateStoreSetting = (body: any) => {
  return ApiInstance.put(`setting/update`, body);
};

export const CreateStoreSetting = (body: any) => {
  return ApiInstance.post(`setting/create`, body);
};

export const GetDetailReceiptSetting = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/receipt-setting/detail`);
};

export const UpdateReceiptSetting = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/receipt-setting/update`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export const GetStoreSaleAnalytics = (query: any) => {
  // TODO: open it when have real data
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/analytics` + params);

  // TODO: remove it when have real data
  // !Fake return data
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     const sampleData = DATA_STORE_SALE_ANALYTICS;
  //     console.log("[FAKE DATA] FOR THE GetStoreSaleAnalytics", sampleData);
  //     resolve({
  //       data: sampleData,
  //     });
  //   }, 1000);
  // }) as any;
};

export const GetStoreSaleAnalyticsByProduct = (query: any) => {
  // TODO: open it when have real data
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/analytics-by-product` + params);

  // TODO: remove it when have real data
  // !Fake return data
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     const sampleData = DATA_STORE_SALE_ANALYTICS_BY_PRODUCT;
  //     console.log(
  //       "[FAKE DATA] FOR THE GetStoreSaleAnalyticsByProduct",
  //       sampleData
  //     );
  //     resolve({
  //       data: sampleData,
  //     });
  //   }, 1000);
  // }) as any;
};

export const GetStoreSaleAnalyticsByStore = (query: any) => {
  // TODO: open it when have real data
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/analytics-by-category` + params);

  // TODO: remove it when have real data
  // !Fake return data
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     const sampleData = DATA_STORE_SALE_ANALYTICS_BY_STORE;
  //     console.log(
  //       "[FAKE DATA] FOR THE GetStoreSaleAnalyticsByStore",
  //       sampleData
  //     );
  //     resolve({
  //       data: sampleData,
  //     });
  //   }, 1000);
  // }) as any;
};

export const GetStoreTransactionHistory = (query: any) => {
  // TODO: open it when have real data
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/transaction-history` + params);

  // TODO: remove it when have real data
  // !Fake return data
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     const sampleData = DATA_STORE_TRANSACTION_HISTORY;
  //     console.log("[FAKE DATA] FOR THE GetStoreTransactionHistory", sampleData);
  //     resolve({
  //       data: sampleData,
  //     });
  //   }, 1000);
  // }) as any;
};

export const GetStoreTransactionHistoryDetail = (id: number) => {
  // TODO: open it when have real data
  return ApiInstance.get(`${PATH_URL.STORE}/transaction-history/detail/${id}`);

  // TODO: remove it when have real data
  // !Fake return data
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     const sampleData = DATA_STORE_TRANSACTION_HISTORY_DETAIL;
  //     console.log(
  //       "[FAKE DATA] FOR THE GetStoreTransactionHistoryDetail",
  //       sampleData
  //     );
  //     resolve({
  //       data: sampleData,
  //     });
  //   }, 1000);
  // }) as any;
};

export const GetListCoupon = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/coupon/list` + params);
};

export const DeleteCoupon = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/coupon/delete/${id}`);
};

export const UpdateCoupons = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/coupon/bulk-update`, body);
};

export const DetailCoupon = (id: number) => {
  return ApiInstance.get(`${PATH_URL.STORE}/coupon/${id}`);
};

export const UpdateCoupon = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/coupon/update`, body);
};
