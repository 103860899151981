import { STORE_ROUTER_NAMES } from "@/router/routesNames";

export const ACCOUNT_TYPES = {
  CUSTOMER: "0",
  SYSTEM_ADMIN: "1",
  STORE: "2",
  COMPANY_MANAGER: "3",
  COMPANY_STAFF: "4",
};

export const GET_ACCOUNT_TYPES = (role: string) =>
  Object.entries(ACCOUNT_TYPES).find(([key, value]) => value == role)?.[0];

export const STATUSES = {
  DEACTIVATE: 0,
  ACTIVE: 1,
  BLOCK: 3,
  SOFT_DELETE: 4,
};

export const STAFF_ROLES = {
  ADMIN: "管理者",
  STAFF: "スタッフ",
};

export const DATA_IMPORT_STAFF = {
  email: "メールアドレス",
  name: "社員名",
  role: "管理権限",
  password: "パスワード",
};

export const PATH_URL = {
  USER: "/user",
  STORE: "/store",
  ADMIN: "/system",
  COMPANY: "/company",
  SETTING: "/setting",
};

export const TOKEN_STATUS = {
  EXPIRED: 1,
  USED: 2,
};

export const DATE_FORMAT = {
  MONTH_YEAR: "MM/yyyy",
  YEAR: "yyyy",
  MONTH: "MM",
};

export const DISPLAY_FLAGS = {
  SHOW: 1,
  HIDE: 0,
};

export const TYPE_POS = {
  AUTO: 1,
  SELF: 2,
};

export const REGEX_ONLY_NUMBER = /[^0-9]/g;

export const DRAG_OPTION = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};

export const LANGUAGES = {
  JA: {
    key: "ja",
    value: 1,
    index: 0,
  },
  EN: {
    key: "en",
    value: 2,
    index: 1,
  },
};

export const LEVELS_CATEGORY = {
  PARENT: 1,
  CHILD: 2,
  GRAND_CHILD: 3,
};

export const REFUND_STATUSES = {
  ALLOW: 1,
  DISALLOW: 0,
};

export const REFUND_PERIOD = [3, 5, 7, 9];

export const SALE_PROHIBITED = {
  YES: 1,
  NO: 0,
};

export const TAX_CALC_METHOD = [
  {
    name: "tax_calc_method.round",
    value: "round",
  },
  {
    name: "tax_calc_method.floor",
    value: "floor",
  },
  {
    name: "tax_calc_method.ceil",
    value: "ceil",
  },
];

export const TYPE_REQUIRED_IF = {
  CHECK_VALUE: "checkValue",
  BOOLEAN: "boolean",
};

export const TYPE_CONDITION_SET_PRICE = {
  CURRENCY: 1,
  DISCOUNT_CURRENCY: 2,
  PERCENT_DISCOUNT: 3,
};

export const HOST_URL_IMAGE = process.env.VUE_APP_API_URL + "/image/";

export const STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const STORE_MENUS = [
  {
    title: "商品",
    id: "productManagement",
    icon: "box.svg",
    routers: [
      {
        title: "クーポン設定",
        link: { name: STORE_ROUTER_NAMES.couponSetting },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "在庫管理",
        link: { name: STORE_ROUTER_NAMES.inventoryManagement },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "商品登録",
        link: { name: STORE_ROUTER_NAMES.listProduct },
        icon: ["fas", "clipboard-list"],
      },
    ],
  },
  {
    title: "機器管理",
    id: "machineManagement",
    icon: "machine_managerment.svg",
    routers: [
      {
        title: "レジ登録",
        link: { name: STORE_ROUTER_NAMES.cashRegister },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "自動釣銭機設定",
        link: { name: STORE_ROUTER_NAMES.automaticChangeSetting },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "RFIDリーダ登録",
        link: { name: STORE_ROUTER_NAMES.rfidReaderRegistration },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "レシートプリンタ登録",
        link: { name: STORE_ROUTER_NAMES.receiptPrinterRegistration },
        icon: ["fas", "clipboard-list"],
      },
    ],
  },
  {
    title: "マスタ管理",
    id: "masterManagement",
    icon: "master_setting.svg",
    routers: [
      {
        title: "支払い区分設定",
        link: { name: STORE_ROUTER_NAMES.paymentTypeSetting },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "支払い方法設定",
        link: { name: STORE_ROUTER_NAMES.paymentMethodSetting },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "マスタ設定",
        link: { name: STORE_ROUTER_NAMES.masterSetting },
        icon: ["fas", "clipboard-list"],
      },
    ],
  },
  {
    title: "レシート管理",
    id: "receiptManagement",
    icon: "receipt.svg",
    routers: [
      {
        title: "レシート設定",
        link: { name: STORE_ROUTER_NAMES.receiptSetting },
        icon: ["fas", "clipboard-list"],
      },
    ],
  },
  {
    title: "売上管理",
    id: "salesManagement",
    icon: "sale.svg",
    routers: [
      {
        title: "売上分析",
        link: { name: STORE_ROUTER_NAMES.salesAnalysis },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "商品別売上分析",
        link: { name: STORE_ROUTER_NAMES.salesAnalysisByProduct },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "店舗別売上",
        link: { name: STORE_ROUTER_NAMES.salesByStore },
        icon: ["fas", "clipboard-list"],
      },
      {
        title: "取引履歴一覧",
        link: { name: STORE_ROUTER_NAMES.transactionHistoryList },
        icon: ["fas", "clipboard-list"],
      },
    ],
  },
  {
    title: "顧客管理",
    id: "customerManagement",
    icon: "customer.svg",
    routers: [
      {
        title: "請求一覧",
        icon: ["fas", "clipboard-list"],
        link: { name: STORE_ROUTER_NAMES.billingList },
      },
    ],
  },
];

export const MACHINE_LIMIT = {
  RFID: 100,
  PRINT: 10,
  POS: 10,
  DRAWER: 100,
  CASHBACK: 100,
}


export const COUPON_TABS = {
  CATEGORY: {
    label: "カテゴリで検索",
    value: 1,
  },
  PRODUCT: {
    label: "商品で検索",
    value: 2,
  },
  SET_PRODUCT: {
    label: "セット商品で検索",
    value: 3,
  },
  BUNDLE_PRODUCT: {
    label: "バンドル商品で検索",
    value: 4,
  },
}
export const PRICE = {
  STANDARD: 10000,
  ENTERPRISE: 30000,
  MAINTENANCE: 5000
}
export const PLAN = {
  STANDARD: 1,
  ENTERPRISE: 2,
  ENTERPRISE_PLUS: 3
}
export const PLAN_NAME = {
  [PLAN.STANDARD]: "スタンダード",
  [PLAN.ENTERPRISE]: "エンタープライズ",
  [PLAN.ENTERPRISE_PLUS]: "エンタプライス"
}
export const PAYMENT_METHOD = {
  CREDIT_CARD: 1,
  INVOICE: 2,
  BANK: 3
}
export const PAYMENT_METHOD_NAME = {
  1: "クレジットカード",
  2: "請求書払い",
}
