import {
  LIST_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_DISPLAY_LIST_CATEGORY,
  DETAIL_CATEGORY,
  CREATE_CATEGORY,
  DOWNLOAD_CSV_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_INVENTORY,
  UPDATE_CATEGORIES,
  UPDATE_CHILD_DISPLAY_CATEGORY,
  LIST_PAYMENT_TYPE,
  DELETE_PAYMENT_TYPE,
  CREATE_PAYMENT_TYPE,
  LIST_RFID,
  DELETE_RFID,
  CREATE_RFID,
  LIST_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  GET_STORE_SETTING,
  UPDATE_STORE_SETTING,
  GET_STORE_SALE_ANALYTICS,
  GET_STORE_SALE_ANALYTICS_BY_PRODUCT,
  GET_ANALYTICS_BY_STORE,
  GET_STORE_TRANSACTION_HISTORY,
  GET_STORE_TRANSACTION_HISTORY_DETAIL,
  DETAIL_RECEIPT_SETTING,
  UPDATE_RECEIPT_SETTING,
  UPDATE_POS_MACHINES,
  LIST_INVENTORY,
  LIST_COUPON,
  DELETE_COUPON,
  UPDATE_COUPONS,
  DETAIL_COUPON,
  UPDATE_COUPON,
} from "./../actions.type";
import {
  SET_DETAIL_POS_MACHINE,
  SET_LIST_CASHBACK_MACHINE,
  SET_LIST_DRAWER,
  SET_LIST_POS_MACHINE,
  SET_LIST_PRINT,
  SET_LIST_STORE,
  SET_DETAIL_STORE,
  SET_LOADING,
  SET_LIST_STORE_PAGINATE,
  SET_LIST_CATEGORY,
  SET_DETAIL_CATEGORY,
  SET_CREATED_CATEGORY,
  SET_UPDATED_CATEGORY,
  SET_LOADING_UPDATE_CATEGORY,
  SET_LOADING_UPDATE_INVENTORY,
  SET_LIST_PAYMENT_TYPE,
  SET_LIST_RFID,
  SET_LIST_PAYMENT_METHOD,
  SET_STORE_SETTING,
  SET_STORE_ANALYTICS,
  SET_STORE_ANALYTICS_BY_PRODUCT,
  SET_ANALYTICS_BY_STORE,
  SET_STORE_TRANSACTION_HISTORY,
  SET_STORE_TRANSACTION_HISTORY_DETAIL,
  SET_RECEIPT_SETTING,
  SET_LIST_INVENTORY,
  SET_UPDATED_INVENTORY,
  SET_LIST_COUPON,
  SET_DETAIL_COUPON,
} from "./../mutations.type";
import {
  LIST_STORE,
  DETAIL_STORE,
  CREATE_STORE,
  DELETE_STORE,
  UPDATE_STORE,
  LIST_POS_MACHINE,
  CREATE_CASHBACK,
  CREATE_PRINT,
  CREATE_DRAWER,
  LIST_DRAWER,
  DELETE_DRAWER,
  DELETE_PRINT,
  LIST_PRINT,
  LIST_CASHBACK_MACHINE,
  DELETE_CASHBACK_MACHINE,
  DELETE_POS_MACHINE,
  CREATE_POS,
  UPDATE_DISPLAY_POS,
  DETAIL_POS_MACHINE,
  UPDATE_DISPLAY_STORE,
} from "../actions.type";
import { SET_ERROR, SET_UPDATED_DATA } from "../mutations.type";
import {
  AdminGetListPosMachine,
  AdminGetListStore,
  GetDetailStore,
  CreateCashbackMachine,
  CreateDrawer,
  CreatePosMachine,
  CreatePrint,
  CreateStore,
  DeleteCashbackMachine,
  DeleteDrawer,
  DeletePosMachine,
  DeletePrint,
  DeleteStore,
  DetailPosMachine,
  GetListCashbackMachine,
  GetListDrawer,
  GetListPrint,
  GetListInventory,
  UpdateDisplay,
  UpdateStore,
  UpdateDisplayStore,
  GetListCategory,
  DeleteCategory,
  UpdateDisplayCategory,
  DetailCategory,
  CreateCategory,
  DownloadCSVCategory,
  UpdateCategory,
  UpdateCategories,
  UpdateChildDisplayCategory,
  GetListPaymentType,
  CreatePaymentType,
  DeletePaymentType,
  GetListRfid,
  DeleteRfid,
  CreateRfid,
  GetListPaymentMethod,
  CreatePaymentMethod,
  DeletePaymentMethod,
  GetStoreSetting,
  UpdateStoreSetting,
  CreateStoreSetting,
  GetStoreSaleAnalytics,
  GetStoreSaleAnalyticsByProduct,
  GetStoreSaleAnalyticsByStore,
  GetStoreTransactionHistory,
  GetStoreTransactionHistoryDetail,
  GetDetailReceiptSetting,
  UpdateReceiptSetting,
  UpdatePosMachines,
  UpdateInventories,
  GetListCoupon,
  DeleteCoupon,
  UpdateCoupons,
  DetailCoupon,
  UpdateCoupon,
} from "@/services/api/store.service";
import { STORE_ROUTER_NAMES } from "@/router/routesNames";
import router from "@/router";
import { getDefaultNameCategory } from "@/utils";

const state = {
  loading: false,
  errors: null,
  stores: [],
  store: [],
  drawers: [],
  posMachines: [],
  detailPosMachine: null,
  cashbackMachines: [],
  storesPaginate: [],
  prints: [],
  updatedData: null,
  categories: [],
  createdCategory: null,
  childrenCategories: [],
  updateLanguage: null,
  category: [],
  loadingUpdateCategory: false,
  paymentTypes: [],
  rfidList: [],
  paymentMethods: [],
  settings: {},
  analytics: null,
  productAnalytics: [],
  analyticsByStore: [],
  transactionHistories: [],
  transactionHistoryDetail: {},
  inventories: [],
  coupons: [],
  detailCoupon: {},
};

const getters = {
  getErrors(state: any) {
    return state.errors;
  },
  getChildrenCategories(state: any) {
    return state.childrenCategories.map(
      (item: {
        displayName?: any;
        children?: any;
        id?: any;
        categoryLanguages: string[];
      }) => {
        item.displayName = getDefaultNameCategory(item);
        if (item.children?.length) {
          item.children = item.children.map(
            (child: {
              parentCategoryId: any;
              displayName: string;
              categoryLanguages: string[];
            }) => {
              child.parentCategoryId = item.id;
              child.displayName = getDefaultNameCategory(child);
              return child;
            }
          );
        }
        return item;
      }
    );
  },

  getRootCategories(state: any) {
    return state.categories.map(
      (item: { displayName?: any; id?: any; categoryLanguages: string[] }) => {
        item.displayName = getDefaultNameCategory(item);
        return item;
      }
    );
  },

  getCreateCategory(state: any) {
    return state.createdCategory;
  },
  getLoadingUpdateCategory(state: any) {
    return state.loadingUpdateCategory;
  },
  getListStorePagination(state: any) {
    return state.storesPaginate;
  },
};

const actions = {
  async [LIST_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    query: { page?: number; keyword?: string; storeGroupId?: number }
  ) {
    try {
      const response = await AdminGetListStore(query);
      if (!query?.page) context.commit(SET_LIST_STORE, response.data);
      else context.commit(SET_LIST_STORE_PAGINATE, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_DISPLAY_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplayStore(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_POS_MACHINE](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await AdminGetListPosMachine(query);
      context.commit(SET_LIST_POS_MACHINE, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_DRAWER](context: { commit: (arg0: string, arg1: any) => void }) {
    try {
      const response = await GetListDrawer();
      context.commit(SET_LIST_DRAWER, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_DRAWER](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteDrawer(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_CASHBACK_MACHINE](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = await GetListCashbackMachine();
      context.commit(SET_LIST_CASHBACK_MACHINE, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_CASHBACK_MACHINE](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteCashbackMachine(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_PRINT](context: { commit: (arg0: string, arg1: any) => void }) {
    try {
      const response = await GetListPrint();
      context.commit(SET_LIST_PRINT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_INVENTORY](context: { commit: (arg0: string, arg1: any) => void }, params: any) {
    try {
      const response = await GetListInventory(params);
      context.commit(SET_LIST_INVENTORY, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DETAIL_POS_MACHINE](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const response = await DetailPosMachine(id);
      context.commit(SET_DETAIL_POS_MACHINE, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_POS_MACHINE](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeletePosMachine(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_PRINT](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeletePrint(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteStore(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_CASHBACK](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreateCashbackMachine(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_POS](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreatePosMachine(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_POS_MACHINES](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdatePosMachines(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [DETAIL_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    storeId: any
  ) {
    try {
      const { data } = await GetDetailStore(storeId);
      context.commit(SET_DETAIL_STORE, data);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreateStore(body.data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
      router.push(body.redirectPath || { name: STORE_ROUTER_NAMES.home });
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateStore(body.data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
      router.push(body.redirectPath || { name: STORE_ROUTER_NAMES.home });
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_DISPLAY_POS](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplay(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_DRAWER](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreateDrawer(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_PRINT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreatePrint(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      const response = await GetListCategory(body);
      context.commit(SET_LIST_CATEGORY, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteCategory(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_DISPLAY_LIST_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplayCategory(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING_UPDATE_CATEGORY, true);
      const res = await CreateCategory(body);
      context.commit(SET_LOADING_UPDATE_CATEGORY, false);
      context.commit(SET_CREATED_CATEGORY, res.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING_UPDATE_CATEGORY, false);
    }
  },
  async [UPDATE_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING_UPDATE_CATEGORY, true);
      await UpdateCategory(body);
      context.commit(SET_LOADING_UPDATE_CATEGORY, false);
      context.commit(SET_ERROR, null);
      context.commit(SET_UPDATED_CATEGORY, new Date());
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING_UPDATE_CATEGORY, false);
    }
  },

  async [UPDATE_INVENTORY](
    context: { commit: (arg0: string, arg1:any) => void },
    body: any
  ){
    try {
      context.commit (SET_LOADING, true);
      await UpdateInventories(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
      context.commit(SET_UPDATED_DATA, new Date());
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING_UPDATE_INVENTORY, false);
    }
  },

  async [UPDATE_CATEGORIES](
    context: { commit: (arg0: string, arg1:any) => void },
    body: any
  ){
    try {
      context.commit (SET_LOADING_UPDATE_CATEGORY, true);
      await UpdateCategories(body);
      context.commit(SET_LOADING_UPDATE_CATEGORY, false);
      context.commit(SET_ERROR, null);
      context.commit(SET_UPDATED_CATEGORY, new Date());
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING_UPDATE_CATEGORY, false);
    }
  },
  async [DETAIL_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const response = await DetailCategory(id);
      context.commit(SET_DETAIL_CATEGORY, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_CHILD_DISPLAY_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: { ids: Array<Number>; categories: Array<any> }
  ) {
    context.commit(SET_LOADING, true);
    try {
      const response = await UpdateChildDisplayCategory(body);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
    context.commit(SET_LOADING, false);
  },
  async [DOWNLOAD_CSV_CATEGORY](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = DownloadCSVCategory();
      context.commit(SET_ERROR, null);
      return response;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_PAYMENT_TYPE](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = await GetListPaymentType();
      context.commit(SET_LIST_PAYMENT_TYPE, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_PAYMENT_TYPE](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreatePaymentType(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },
  async [DELETE_PAYMENT_TYPE](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeletePaymentType(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [LIST_RFID](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await GetListRfid(query);
      context.commit(SET_LIST_RFID, response.data || []);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_RFID](context: any, id: number) {
    try {
      await DeleteRfid(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_RFID](context: any, body: any) {
    try {
      context.commit(SET_LOADING, true);
      await CreateRfid(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_PAYMENT_METHOD](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = await GetListPaymentMethod();
      context.commit(SET_LIST_PAYMENT_METHOD, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_PAYMENT_METHOD](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreatePaymentMethod(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },
  async [DELETE_PAYMENT_METHOD](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeletePaymentMethod(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [GET_STORE_SETTING](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = await GetStoreSetting();
      context.commit(SET_STORE_SETTING, response.data || {});
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [UPDATE_STORE_SETTING](context: any, body: any) {
    try {
      context.commit(SET_LOADING, true);
      let response;
      if (body?.data?.id) {
        response = await UpdateStoreSetting(body);
      } else {
        response = await CreateStoreSetting(body);
      }
      context.commit(SET_STORE_SETTING, response.data);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [GET_STORE_SALE_ANALYTICS](context: any, body: any) {
    try {
      const response = await GetStoreSaleAnalytics(body);
      console.log("GET_STORE_SALE_ANALYTICS", response.data);
      context.commit(SET_STORE_ANALYTICS, response.data || {});
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [GET_STORE_SALE_ANALYTICS_BY_PRODUCT](context: any, body: any) {
    try {
      const response = await GetStoreSaleAnalyticsByProduct(body);
      context.commit(SET_STORE_ANALYTICS_BY_PRODUCT, response.data || {});
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [GET_ANALYTICS_BY_STORE](context: any, body: any) {
    try {
      const response = await GetStoreSaleAnalyticsByStore(body);
      context.commit(SET_ANALYTICS_BY_STORE, response.data || {});
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [GET_STORE_TRANSACTION_HISTORY](context: any, body: any) {
    try {
      const response = await GetStoreTransactionHistory(body);
      context.commit(SET_STORE_TRANSACTION_HISTORY, response.data || {});
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [GET_STORE_TRANSACTION_HISTORY_DETAIL](context: any, body: any) {
    try {
      const response = await GetStoreTransactionHistoryDetail(body);
      context.commit(SET_STORE_TRANSACTION_HISTORY_DETAIL, response.data || {});
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [DETAIL_RECEIPT_SETTING](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = await GetDetailReceiptSetting();
      context.commit(SET_RECEIPT_SETTING, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [UPDATE_RECEIPT_SETTING](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      await UpdateReceiptSetting(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
  },


  async [LIST_COUPON](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await GetListCoupon(query);
      context.commit(SET_LIST_COUPON, response.data || []);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [DELETE_COUPON](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteCoupon(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [UPDATE_COUPONS](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateCoupons(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },

  async [UPDATE_COUPON](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateCoupon(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },

  async [DETAIL_COUPON](
    context: { commit: (arg0: string, arg1: any) => void;},
    id: number
  ) {
    try {
      const response = await DetailCoupon(id);
      context.commit(SET_DETAIL_COUPON, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LIST_DRAWER](state: any, responseData: any) {
    state.drawers = responseData;
  },
  [SET_LIST_STORE](state: any, responseData: any) {
    state.stores = responseData;
  },
  [SET_LIST_STORE_PAGINATE](state: any, responseData: any) {
    state.storesPaginate = responseData;
  },
  [SET_LIST_CASHBACK_MACHINE](state: any, responseData: any) {
    state.cashbackMachines = responseData;
  },
  [SET_LIST_POS_MACHINE](state: any, responseData: any) {
    state.posMachines = responseData;
  },
  [SET_DETAIL_POS_MACHINE](state: any, responseData: any) {
    state.detailPosMachine = responseData;
  },
  [SET_LIST_PRINT](state: any, responseData: any) {
    state.prints = responseData;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_UPDATED_DATA](state: any, data: any) {
    state.updatedData = data;
  },
  [SET_DETAIL_STORE](state: any, responseData: any) {
    state.store = responseData;
  },
  [SET_LIST_CATEGORY](state: any, responseData: any) {
    state.categories = responseData;
  },
  [SET_DETAIL_CATEGORY](state: any, responseData: any) {
    state.childrenCategories = responseData;
  },
  [SET_CREATED_CATEGORY](state: any, responseData: any) {
    state.createdCategory = responseData;
  },
  [SET_UPDATED_CATEGORY](state: any, responseData: any) {
    state.updateLanguage = responseData;
  },
  [SET_UPDATED_INVENTORY](state: any, responseData: any) {
    state.updateLanguage = responseData;
  },
  [SET_LOADING_UPDATE_CATEGORY](state: any, responseData: any) {
    state.loadingUpdateCategory = responseData;
  },
  [SET_LIST_PAYMENT_TYPE](state: any, responseData: any) {
    state.paymentTypes = responseData;
  },

  [SET_LIST_RFID](state: any, responseData: any) {
    state.rfidList = responseData;
  },
  [SET_LIST_PAYMENT_METHOD](state: any, responseData: any) {
    state.paymentMethods = responseData;
  },
  [SET_STORE_SETTING](state: any, responseData: any) {
    state.settings = responseData;
  },

  [SET_STORE_ANALYTICS](state: any, responseData: any) {
    console.log("SET_STORE_ANALYTICS", responseData);
    state.analytics = responseData;
  },
  [SET_STORE_ANALYTICS_BY_PRODUCT](state: any, responseData: any) {
    state.productAnalytics = responseData;
  },
  [SET_ANALYTICS_BY_STORE](state: any, responseData: any) {
    state.analyticsByStore = responseData;
  },

  [SET_STORE_TRANSACTION_HISTORY](state: any, responseData: any) {
    state.transactionHistories = responseData;
  },
  [SET_STORE_TRANSACTION_HISTORY_DETAIL](state: any, responseData: any) {
    state.transactionHistoryDetail = responseData;
  },
  [SET_RECEIPT_SETTING](state: any, responseData: any) {
    state.receiptSetting = responseData;
  },
  [SET_LIST_INVENTORY](state: any, responseData: any) {
    state.inventories = responseData;
  },
  [SET_LIST_COUPON](state: any, responseData: any) {
    state.coupons = responseData;
  },
  [SET_DETAIL_COUPON](state: any, responseData: any) {
    state.detailCoupon = responseData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
