<template>
  <nav class="sidebar">
    <p role="button" @click="naviageTo(COMPANY_ROUTER_NAMES.home)">
      {{ sidebarTitle }}
    </p>
    <div
      class="sidebar__item"
      v-if="displayNote"
      @click="naviageTo(COMPANY_ROUTER_NAMES.home)"
    >
      <img src="/icons/notebook.svg" alt="image" />
      <p>トップ</p>
    </div>
    <div class="sidebar__item" v-if="currentUser?.role != ACCOUNT_TYPES.STORE">
      <img src="/icons/box.svg" alt="image" />
      <p>商品</p>
    </div>
    <template v-if="currentUser?.role === ACCOUNT_TYPES.STORE">
      <div
        class="sidebar__item"
        v-for="(menu, index) in STORE_MENUS"
        :key="index"
        @click="toRouterGroup(menu)"
      >
        <img :src="getImageUrl(menu.icon)" alt="image" />
        <p>{{ menu.title }}</p>
      </div>
    </template>
    <div
      class="sidebar__item"
      @click="naviageTo(STORE_ROUTER_NAMES.accountSetting)"
    >
      <img src="../../../assets/setting-sidebar.png" alt="image" />
      <p>アカウント管理</p>
    </div>
  </nav>
</template>

<script>
import { COMPANY_ROUTER_NAMES } from "@/router/companyGroup";
import { STORE_ROUTER_NAMES } from "@/router/storeGroup";
import { ACCOUNT_TYPES, STORE_MENUS } from "@/constants";

export default {
  name: "SideBar",
  setup() {
    return {
      COMPANY_ROUTER_NAMES,
      ACCOUNT_TYPES,
      STORE_ROUTER_NAMES,
      STORE_MENUS,
    };
  },
  props: {
    displayNote: Boolean,
    sidebarTitle: String,
  },
  methods: {
    naviageTo(name) {
      this.$router.push({ name });
    },
    toRouterGroup(routerInfo) {
      this.$router.push({
        name: STORE_ROUTER_NAMES.home,
        query: { routerGroupId: routerInfo.id },
      });
    },
    getImageUrl(filename) {
      return "/icons/" + filename;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/layouts/admin/sidebar.scss";
</style>
