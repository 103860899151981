export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const ADMIN_LOGIN = "adminLogin";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CHANGE_EMAIL = "changeEmail";
export const CHANGE_PASSWORD = "changePassword";
export const RESET_PASSWORD = "resetPassword";
export const RESET_PASSWORD_CONFIRM = "resetPasswordConfirm";
export const CONFIRM_CHANGE_EMAIL = "confirmChangeEmail";
export const AUTH_CHECK_VALID_TOKEN = "authCheckValidToken";

// STORE
export const LIST_STORE = "getListStore";
export const DETAIL_STORE = "getDetailStore";
export const CREATE_STORE = "createStore";
export const UPDATE_STORE = "updateStore";
export const DELETE_STORE = "deleteStore";

export const LIST_DRAWER = "getListDrawer";
export const LIST_PRINT = "getListPrint";
export const LIST_INVENTORY = "getListInventory";
export const LIST_CASHBACK_MACHINE = "getListCashback";
export const DELETE_POS_MACHINE = "deletePosMachine";
export const DELETE_PRINT = "deletePrint";
export const DELETE_DRAWER = "deleteDrawer";
export const DELETE_CASHBACK_MACHINE = "deleteCashback";
export const LIST_POS_MACHINE = "getListPosMachine";
export const DETAIL_POS_MACHINE = "getDetailPosMachine";
export const UPDATE_POS_MACHINES = "updatePosMachines";
export const CREATE_CASHBACK = "createCashback";
export const CREATE_POS = "createPosMachine";
export const UPDATE_DISPLAY_POS = "updatePosDisplay";
export const CREATE_DRAWER = "createDrawer";
export const CREATE_PRINT = "createPrint";
export const UPDATE_DISPLAY_STORE = "updateStoreDisplay";

export const LIST_CATEGORY = "getListCategory";
export const DELETE_CATEGORY = "deleteCategory";
export const UPDATE_DISPLAY_LIST_CATEGORY = "updateCategoryDisplay";
export const DETAIL_CATEGORY = "getDetailCategory";
export const UPDATE_CATEGORIES = 'updateCategories';
export const CREATE_CATEGORY = "createCategory";
export const UPDATE_CATEGORY = "updateCategory";
export const UPDATE_INVENTORY = "updateInventory";
export const DOWNLOAD_CSV_CATEGORY = "DownloadCsvCategory";
export const UPDATE_CHILD_DISPLAY_CATEGORY = "updateChildDisplayCategory";

export const LIST_SET_PRODUCT = "getListSetProduct";
export const DETAIL_SET_PRODUCT = "getDetailSetProduct";
export const CREATE_SET_PRODUCT = "createSetProduct";
export const UPDATE_DISPLAY_SET_PRODUCT = "updateDisplaySetProduct";
export const DELETE_SET_PRODUCT = "deleteSetProduct";
export const UPDATE_SET_PRODUCT = "updateSetProduct";
export const DOWNLOAD_CSV_SET_PRODUCT = "downloadCsvSetProduct";
export const IMPORT_SET_PRODUCT = "importSetProduct";
export const LIST_PAYMENT_TYPE = "listPaymentType";
export const DELETE_PAYMENT_TYPE = "deletePaymentType";
export const CREATE_PAYMENT_TYPE = "createPaymentType";
export const LIST_PAYMENT_METHOD = "listPaymentMethod";
export const DELETE_PAYMENT_METHOD = "deletePaymentMethod";
export const CREATE_PAYMENT_METHOD = "createPaymentMethod";

export const LIST_RFID = "getListRfid";
export const DELETE_RFID = "deleteRfid";
export const CREATE_RFID = "createRfid";

export const GET_STORE_SETTING = "getStoreSetting";
export const UPDATE_STORE_SETTING = "updateStoreSetting";

export const GET_STORE_SALE_ANALYTICS = "getStoreSaleAnalytics";
export const GET_STORE_SALE_ANALYTICS_BY_PRODUCT =
  "getStoreSaleAnalyticsByProduct";
export const GET_ANALYTICS_BY_STORE = "getAnalyticsByStore";
export const GET_STORE_TRANSACTION_HISTORY = "getStoreTransactionHistory";
export const GET_STORE_TRANSACTION_HISTORY_DETAIL =
  "getStoreTransactionHistoryDetail";

export const DETAIL_RECEIPT_SETTING = "getDetailReceiptSetting";
export const UPDATE_RECEIPT_SETTING = "updateReceiptSetting";

export const LIST_COUPON = "getListCoupon";
export const DETAIL_COUPON = "getDetailCoupon";
export const CREATE_COUPON = "createCoupon";
export const UPDATE_COUPON = "updateCoupon";
export const UPDATE_COUPONS = "updateCoupons";
export const DELETE_COUPON = "deleteCoupon";
//PRODUCT
export const LIST_PRODUCT = "getListProduct";
export const DETAIL_PRODUCT = "getDetailProduct";
export const UPDATE_DISPLAY_PRODUCT = "updateDisplayProduct";
export const DELETE_PRODUCT = "deleteProduct";
export const CREATE_PRODUCT = "createProduct";
export const UPDATE_PRODUCT = "updateProduct";

export const LIST_BUNDLE_PRODUCT = "getListBundleProduct";
export const DELETE_BUNDLE_PRODUCT = "deleteBundleProduct";
export const UPDATE_DISPLAY_BUNDLE_PRODUCT = "updateDisplayBundleProduct";
export const DOWNLOAD_CSV_BUNDLE_PRODUCT = "downloadCsvBundleProduct";
export const DETAIL_BUNDLE_PRODUCT = "getDetailBundleProduct";
export const UPDATE_BUNDLE_PRODUCT = "updateBundleProduct";

// COMPANY
export const LIST_STORE_GROUP = "getListStoreGroup";
export const DELETE_STORE_GROUP = "deleteStoreGroup";
export const UPDATE_DISPLAY_STORE_GROUP = "updateStoreGroupDisplay";

export const LIST_PERMISSION = "getListPermission";
export const DELETE_PERMISSION = "deletePermission";
export const UPDATE_DISPLAY_PERMISSION = "updatePermissionDisplay";
export const DETAIL_PERMISSION = "getDetailPermission";
export const UPDATE_PERMISSION = "updatePermission";

export const COMPANY_LIST_STAFF = "companyGetListStaff";
export const COMPANY_CREATE_STAFF = "companyCreateStaff";
export const COMPANY_UPDATE_STAFF = "companyUpdateStaff";
export const COMPANY_UPDATE_DISPLAY_STAFF = "companyUpdateDisplayStaff";
export const COMPANY_UPDATE_STATUS_STAFF = "companyUpdateStatusStaff";
export const COMPANY_DELETE_STAFF = "companyDeleteStaff";
export const COMPANY_IMPORT_STAFF = "companyImportStaff";
export const COMPANY_DOWNLOAD_CSV = "companyDowloadCsv";
export const CREATE_PLAN = "createPlan";
export const DETAIL_PLAN = "detailPlan";
export const DELETE_PLAN = "deletePlan";
export const UPDATE_PLAN_CUSTOMER = "updatePlanCustomer";

// ADMIN
export const CREATE_COMPANY = "createCompany";
export const DETAIL_COMPANY = "getDetailCompany";
export const UPDATE_COMPANY = "updateCompany";
export const LIST_STORE_ADMIN = "getListStoreAdmin";

// SETTING
export const CREATE_SETTING = "createSetting";
export const GET_MASTER_SETTING = "getMasterSetting";
export const UPDATE_MASTER_SETTING = "updateMasterSetting";

//PLASTIC BAG
export const GET_PLASTIC_BAGS = "getPlasticBags";
export const DELETE_PLASTIC_BAGS = "deletePlasticBags";
export const UPDATE_PLASTIC_BAGS_DISPLAY = "updatePlasticBagsDisplay";
