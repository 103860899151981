export interface IRoutesNames {
  customer: string[];
  store: string[];
  company: string[];
  admin: string[];
}

const routesNames: Readonly<IRoutesNames> = {
  customer: ["customerLogin", "customer"],
  store: ["customerLogin", "store"],
  company: ["adminLogin", "company"],
  admin: ["adminLogin", "admin"],
};

export const STORE_ROUTER_NAMES = {
  home: routesNames.store[1],
  login: routesNames.store[0],
  detailCategory: "detailCategory",
  categoryTranslation: "categoryTranslation",
  changePassword: "storeChangePassword",
  changeEmail: "storeChangeEmail",
  createProduct: "createProduct",
  productDetailRegistrationTwo: "productDetailRegistrationTwo",
  modalTargetProduct: "modalTargetProduct",
  productRegistrationMultilingual: "productRegistrationMultilingual",
  modalProductVariationRegistration: "modalProductVariationRegistration",
  modalProductVariationRegistrationTwo: "modalProductVariationRegistrationTwo",
  registerBag: "registerBag",
  modalTargetProductSelection: "modalTargetProductSelection",
  modalSetProductTarget: "modalSetProductTarget",
  productRegistrationBundle: "productRegistrationBundle",
  bundleProduct: "bundleProduct",
  modalEligibleProductSelection: "modalEligibleProductSelection",
  modalBundleProductTarget: "modalBundleProductTarget",
  saleSetting: "saleSetting",
  saleDetailsSetting: "saleDetailsSettings",
  modalSaleTargetProductSelectionCategory:
    "modalSaleTargetProductSelectionCategory",
  modalSaleTargetProductSelectionIndividual:
    "modalSaleTargetProductSelectionIndividual",
  modalSaleTargetStoreSettings: "modalSaleTargetStoreSettings",
  couponSetting: "couponSetting",
  addCoupon: "addCoupon",
  modalCouponTargetProductSelectionCategory:
    "modalCouponTargetProductSelectionCategory",
  modalCouponEligibleProductSelectionProduct:
    "modalCouponEligibleProductSelectionProduct",
  modalCouponEligibleStoreSettings: "modalCouponEligibleStoreSettings",
  cashRegister: "cashRegister",
  cashRegisterDetail: "cashRegisterDetail",
  cashRegisterCreate: "cashRegisterCreate",
  cashDrawerRegistration: "cashDrawerRegistration",
  automaticChangeSetting: "automaticChangeSetting",
  rfidReaderRegistration: "rfidReaderRegistration",
  receiptPrinterRegistration: "receiptPrinterRegistration",
  paymentTypeSetting: "paymentTypeSetting",
  paymentMethodSetting: "paymentMethodSetting",
  depositCategoryRegistration: "depositCategoryRegistration",
  withdrawalClassificationRegistration: "withdrawalClassificationRegistration",
  inventoryManagement: "inventoryManagement",
  receiptSetting: "receiptSetting",
  masterSetting: "storeMasterSetting",
  salesAnalysis: "salesAnalysis",
  salesAnalysisByProduct: "salesAnalysisByProduct",
  salesByStore: "salesByStore",
  transactionHistoryList: "transactionHistoryList",
  transactionHistoryDetail: "transactionHistoryDetail",
  transactionHistoryEdit: "transactionHistoryEdit",
  depositWithdrawalHistory: "depositWithdrawalHistory",
  cashRegisterInspectionHistory: "cashRegisterInspectionHistory",
  cashRegisterInspectionHistoryDetail:
    "cashRegisterInspectionPaymentHistoryDetail",
  billingList: "storeBillingList",
  accountSetting: "storeAccountSetting",
  listProduct: "listProduct",
  detailProduct: "detailProduct",
};

export default routesNames;
